import PropTypes from 'prop-types';
import React from 'react';

export function TextArea({
  handleChange,
  label,
  name,
  required = true,
  rows = 5,
  value,
}) {
  return (
    <div>
      <label htmlFor={name}>
        <span className="uppercase">{label}:</span>
        <textarea
          className="w-full px-3 py-2 font-sans leading-tight text-gray-700 transition duration-150 ease-in-out bg-white border-2 rounded appearance-none hover:bg-gray-100 focus:outline-none focus:border-black"
          id={name}
          name={name}
          value={value}
          onChange={handleChange}
          required={required}
          rows={rows}
        />
      </label>
    </div>
  );
}

TextArea.propTypes = {
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  rows: PropTypes.string,
  value: PropTypes.string.isRequired,
};
