import React, { useState } from 'react';
import Image from 'gatsby-image';
import NukaCarousel from 'nuka-carousel';

import { useGraphQL } from '../hooks';
import { renderDotControls } from './renderDotControls';

export function Carousel() {
  const data = useGraphQL();

  const [index, setIndex] = useState(0);

  return (
    <div className="relative">
      <NukaCarousel
        slideIndex={index}
        afterSlide={(slideIndex) => setIndex(slideIndex)}
        autoplay
        autoplayInterval={5000}
        cellAlign="center"
        dragging={false}
        easing="easeCubicInOut"
        enableKeyboardControls
        renderCenterLeftControls={null}
        renderCenterRightControls={(props) =>
          renderDotControls(props, setIndex)
        }
        renderBottomCenterControls={null}
        swiping
        wrapAround
      >
        {data.hero.edges.map((edge) => (
          <div key={edge.node.id}>
            <Image
              className="absolute inset-0 w-full h-screen"
              fluid={edge.node.childImageSharp.fluid}
              alt=""
            />
          </div>
        ))}
      </NukaCarousel>
    </div>
  );
}
