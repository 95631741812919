import PropTypes from 'prop-types';
import React from 'react';
import { useTransition, animated } from 'react-spring';
import { DialogOverlay, DialogContent } from '@reach/dialog';

import { ContactForm } from './ContactForm';

export function Modal({ isModalOpen, handleCloseModal }) {
  const transitions = useTransition(isModalOpen, null, {
    from: { opacity: 0, transform: 'translate3d(0, 1rem, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0, 0rem, 0)' },
    leave: { opacity: 0, transform: 'translate3d(0, -1rem, 0)' },
  });

  const AnimatedDialogOverlay = animated(DialogOverlay);
  const AnimatedDialogContent = animated(DialogContent);

  return transitions.map(
    ({ item, key, props: { opacity, transform } }) =>
      item && (
        <AnimatedDialogOverlay
          key={key}
          onDismiss={handleCloseModal}
          style={{ opacity }}
          className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur"
        >
          <AnimatedDialogContent
            aria-label="Show contact form"
            className="flex items-center justify-center min-h-screen bg-transparent"
            style={{ transform }}
          >
            <ContactForm handleCloseModal={handleCloseModal} />
          </AnimatedDialogContent>
        </AnimatedDialogOverlay>
      )
  );
}

Modal.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
};
