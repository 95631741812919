import { useStaticQuery, graphql } from 'gatsby';

export function useGraphQL() {
  const data = useStaticQuery(graphql`
    query HeroQuery {
      hero: allFile(
        filter: { dir: { regex: "/hero-images/" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      logo: file(absolutePath: { regex: "/logo.png/" }) {
        childImageSharp {
          fixed(width: 56, height: 48) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return data;
}
